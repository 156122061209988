<template>
  <page-header-wrapper>
    <a-card :bordered="false">
      <!-- 条件搜索 -->
      <div class="table-page-search-wrapper">
        <a-form layout="inline">
          <a-row :gutter="48">
            <a-col :md="8" :sm="24">
              <a-form-item label="卡号" prop="simNo">
                <a-input v-model="queryParam.simNo" placeholder="请输入卡号" allow-clear />
              </a-form-item>
            </a-col>
            <a-col :md="8" :sm="24">
              <a-form-item label="sim卡ICCID号码" prop="iccid">
                <a-input v-model="queryParam.iccid" placeholder="请输入sim卡ICCID号码" allow-clear />
              </a-form-item>
            </a-col>
            <template v-if="advanced">
              <a-col :md="8" :sm="24">
                <a-form-item label="激活状态" prop="activation">
                  <a-select
                    placeholder="请选择激活状态"
                    v-model="queryParam.activation"
                    style="width: 100%"
                    allow-clear
                  >
                    <a-select-option v-for="item in activationOptions" :key="item.dictValue" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
              <a-col :md="8" :sm="24">
                <a-form-item label="是否删除" prop="status">
                  <a-select placeholder="请选择是否删除" v-model="queryParam.status" style="width: 100%" allow-clear>
                    <a-select-option v-for="item in statusOptions" :key="item.dictValue" :value="item.dictValue">{{
                      item.dictLabel
                    }}</a-select-option>
                  </a-select>
                </a-form-item>
              </a-col>
            </template>
            <a-col :md="(!advanced && 8) || 24" :sm="24">
              <span
                class="table-page-search-submitButtons"
                :style="(advanced && { float: 'right', overflow: 'hidden' }) || {}"
              >
                <a-button type="primary" @click="handleQuery"><a-icon type="search" />查询</a-button>
                <a-button style="margin-left: 8px" @click="resetQuery"><a-icon type="redo" />重置</a-button>
                <a @click="toggleAdvanced" style="margin-left: 8px">
                  {{ advanced ? '收起' : '展开' }}
                  <a-icon :type="advanced ? 'up' : 'down'" />
                </a>
              </span>
            </a-col>
          </a-row>
        </a-form>
      </div>
      <!-- 操作 -->
      <a-toolbar :loading="loading" refresh @refreshQuery="getList" tid="1">
        <div class="table-operations">
          <a-button type="primary" @click="$refs.createForm.handleAdd()" v-hasPermi="['tenant:jt808:deviceSim:add']">
            <a-icon type="plus" />新增
          </a-button>
          <a-button
            type="primary"
            :disabled="single"
            @click="$refs.createForm.handleUpdate(undefined, ids)"
            v-hasPermi="['tenant:jt808:deviceSim:edit']"
          >
            <a-icon type="edit" />修改
          </a-button>
          <a-button
            type="danger"
            :disabled="multiple"
            @click="handleDelete"
            v-hasPermi="['tenant:jt808:deviceSim:remove']"
          >
            <a-icon type="delete" />删除
          </a-button>
          <a-button
            type="dashed"
            @click="$refs.importExcel.importExcelHandleOpen()"
            v-hasPermi="['tenant:jt808:deviceSim:import']"
          >
            <a-icon type="import" />导入
          </a-button>
          <a-button type="primary" @click="handleExport" v-hasPermi="['tenant:jt808:deviceSim:export']">
            <a-icon type="download" />导出
          </a-button>
          <!-- <a-button
          type="dashed"
          shape="circle"
          :loading="loading"
          :style="{ float: 'right' }"
          icon="reload"
          @click="getList"
        /> -->
        </div>
      </a-toolbar>

      <!-- 增加修改 -->
      <create-form ref="createForm" @ok="getList" />
      <!-- 数据展示 -->
      <a-table
        :loading="loading"
        :size="tableSize"
        rowKey="id"
        tid="1"
        :columns="columns"
        :data-source="list"
        :row-selection="{ selectedRowKeys: selectedRowKeys, onChange: onSelectChange }"
        :pagination="false"
      >
        <span slot="activation" slot-scope="text, record">
          {{ activationMap[record.activation] }}
        </span>
        <span slot="operation" slot-scope="text, record">
          <a-divider type="vertical" v-hasPermi="['tenant:jt808:deviceSim:edit']" />
          <a @click="$refs.createForm.handleUpdate(record, undefined)" v-hasPermi="['tenant:jt808:deviceSim:edit']">
            <a-icon type="edit" />修改
          </a>
          <a
            @click="$refs.createForm.handleUpdate(record, undefined, 'read')"
            v-hasPermi="['tenant:jt808:deviceSim:query']"
          >
            <a-icon type="info-circle" />查看
          </a>
          <a-divider type="vertical" v-hasPermi="['tenant:jt808:deviceSim:remove']" />
          <a @click="handleDelete(record)" v-hasPermi="['tenant:jt808:deviceSim:remove']">
            <a-icon type="delete" />删除
          </a>
        </span>
      </a-table>
      <!-- 分页 -->
      <a-pagination
        class="ant-table-pagination"
        show-size-changer
        show-quick-jumper
        :current="queryParam.pageNum"
        :total="total"
        :page-size="queryParam.pageSize"
        :showTotal="(total) => `共 ${total} 条`"
        @showSizeChange="onShowSizeChange"
        @change="changeSize"
      />
    </a-card>
    <import-excel
      ref="importExcel"
      title="SIM卡管理"
      @ok="getList"
      :importTemplate="importTemplate"
      :importData="importData"
    />
  </page-header-wrapper>
</template>

<script>
import { listDeviceSim, delDeviceSim, exportDeviceSim, importTemplate, importData } from '@/api/jt808/deviceSim'
import CreateForm from './modules/CreateForm'
import ImportExcel from '../components/ImportExcel'

export default {
  name: 'DeviceSim',
  components: {
    CreateForm,
    ImportExcel
  },
  data() {
    return {
      list: [],
      selectedRowKeys: [],
      selectedRows: [],
      // 高级搜索 展开/关闭
      advanced: false,
      // 非单个禁用
      single: true,
      // 非多个禁用
      multiple: true,
      ids: [],
      loading: false,
      total: 0,
      importTemplate,
      importData,
      // 创建时间时间范围
      daterangeCreateTime: [],
      // 查询参数
      queryParam: {
        simNo: null,
        iccid: null,
        activation: undefined,
        status: undefined,
        pageNum: 1,
        pageSize: 10
      },
      columns: [
        // {
        //   title: 'ID',
        //   dataIndex: 'id',
        //   ellipsis: true,
        //   align: 'center'
        // },
        {
          title: '卡号',
          dataIndex: 'simNo',
          ellipsis: true,
          align: 'center'
        },
        {
          title: 'sim卡ICCID号码',
          dataIndex: 'iccid',
          ellipsis: true,
          align: 'center'
        },
        {
          title: '激活状态',
          dataIndex: 'activation',
          scopedSlots: { customRender: 'activation' },
          ellipsis: true,
          align: 'center'
        },
        {
          title: '操作',
          dataIndex: 'operation',
          width: '18%',
          scopedSlots: { customRender: 'operation' },
          align: 'center'
        }
      ],
      activationOptions: [
        { dictValue: '0', dictLabel: '未激活' },
        { dictValue: '1', dictLabel: '已激活' }
      ],
      activationMap: {
        0: '未激活',
        1: '已激活'
      },
      statusOptions: []
    }
  },
  filters: {},
  created() {
    this.getDicts('iot_del_yesno').then((response) => {
      this.statusOptions = response.data
    })
    this.getList()
  },
  computed: {},
  watch: {},
  methods: {
    /** 查询sim卡管理列表 */
    getList() {
      this.loading = true
      this.queryParam.params = {}
      if (
        this.daterangeCreateTime !== null &&
        this.daterangeCreateTime !== '' &&
        this.daterangeCreateTime.length !== 0
      ) {
        this.queryParam.params['beginCreateTime'] = this.daterangeCreateTime[0]
        this.queryParam.params['endCreateTime'] = this.daterangeCreateTime[1]
      }
      listDeviceSim(this.queryParam).then((response) => {
        this.list = response.rows
        this.total = response.total
        this.loading = false
      })
    },
    /** 搜索按钮操作 */
    handleQuery() {
      this.queryParam.pageNum = 1
      this.getList()
    },
    /** 重置按钮操作 */
    resetQuery() {
      this.daterangeCreateTime = []
      this.queryParam = {
        simNo: undefined,
        iccid: undefined,
        activation: undefined,

        status: undefined,
        pageNum: 1,
        pageSize: 10
      }
      this.handleQuery()
    },
    onShowSizeChange(current, pageSize) {
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    changeSize(current, pageSize) {
      this.queryParam.pageNum = current
      this.queryParam.pageSize = pageSize
      this.getList()
    },
    onSelectChange(selectedRowKeys, selectedRows) {
      this.selectedRowKeys = selectedRowKeys
      this.selectedRows = selectedRows
      this.ids = this.selectedRows.map((item) => item.id)
      this.single = selectedRowKeys.length !== 1
      this.multiple = !selectedRowKeys.length
    },
    toggleAdvanced() {
      this.advanced = !this.advanced
    },
    /** 删除按钮操作 */
    handleDelete(row) {
      var that = this
      const ids = row.id || this.ids
      this.$confirm({
        title: '确认删除所选中数据?',
        content: '当前选中编号为' + ids + '的数据',
        onOk() {
          return delDeviceSim(ids).then(() => {
            that.onSelectChange([], [])
            that.getList()
            that.$message.success('删除成功', 3)
          })
        },
        onCancel() {}
      })
    },
    /** 导出按钮操作 */
    handleExport() {
      var that = this
      this.$confirm({
        title: '是否确认导出?',
        content: '此操作将导出当前条件下所有数据而非选中数据',
        onOk() {
          return exportDeviceSim(that.queryParam).then((response) => {
            that.download(response.msg)
            that.$message.success('导出成功', 3)
          })
        },
        onCancel() {}
      })
    }
  }
}
</script>
